.App {
  text-align: center;
}

.App-Left {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-image:url(bar.jpg);
  background-size: 100% 100%;
  min-height:35vh;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);

  color: white;
  background-repeat: no-repeat;
}



.Back-Bar {
  height: 300px;
  width: 50%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.column {
  float: left;
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.Empty-Row {
  height: 8vh;
  
  
}

.Empty-Row-Small {
  height: 0vh;
  
}

.TransBackGround {
  background: rgba(239, 237, 231, .7); 
  
}

h1 { color: black; font-family: 'Source Sans Pro', sans-serif; font-size: 50px; font-weight: normal; line-height: 40px; margin: 0; }
p, .mainfont { color: black; font-family: 'Source Sans Pro', sans-serif; font-size: 18px; line-height: 48px; margin: 0; font-weight: 500;}


.link {
  font-family: 'Source Sans Pro', sans-serif;
	  color: black;
    cursor: pointer;
    font-size: 25px;
    padding: 10px 20px;
    border: 2px solid #ebeef2;
    text-decoration: none;
}

.Small-Link {
  font-family: 'Source Sans Pro', sans-serif;
	  color: black;
    cursor: pointer;
    font-size: 18px;
    padding: 5px 10px;
    text-align: left;
}

.Yard-Font {
  font-family: 'Rouge Script', cursive;
  font-size: 28px;
  padding: 2px 3px;
  text-decoration: none;
}


a:hover, a:active {
  background:rgba(199, 199, 136, .7);;
  color: black;
  font-weight: 600;
  
}

a {
  position: relative;
  display: inline-block;
  
  background-color: white;
  }









  input[type=text], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid white;
    border-radius: 4px;
    resize: vertical;
  }
  
  label {
    padding: 12px 12px 12px 0;
    display: inline-block;
  }
  
  input[type=submit] {
    background-color: #c7c788;
    color: black;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 12px;
    margin-right: 20%;
    float: right;
    font-weight: 900;
   
  }
  
  input[type=submit]:hover {
    background-color: white;
  }
  
  .container {
    border-radius: 5px;
    background:rgba(199, 199, 136, .5);
    padding: 20px;
  }
  
  .col-25 {
    float: left;
    width: 25%;
    margin-top: 6px;
  }
  
  .col-75 {
    float: left;
    width: 55%;
    margin-top: 6px;
  }
  
  /* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25, .col-75, input[type=submit] {
    width: 100%;
    margin-top: 0;
  }
}













.card {
  --card-gradient: rgba(0, 0, 0, 0.8);
  --card-gradient: white,rgb(247, 244, 246);
  --card-blend-mode: overlay;

  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0.05rem 0.1rem 0.3rem -0.03rem rgba(0, 0, 0, 0.45);
  padding-bottom: 1rem;
  background-image: linear-gradient(
    var(--card-gradient),
    white max(9.5rem, 27vh)
  );
  overflow: hidden;
}

  img {
    border-radius: 0.5rem 0.5rem 0 0;
    width: 100%;
    object-fit: cover;
    max-height: max(10rem, 30vh);
    aspect-ratio: 4/3;
    mix-blend-mode: var(--card-blend-mode);

    ~ * {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }




/* Additional demo display styles */
* {
  box-sizing: border-box;
}

.body-part {
  display: grid;
  place-content: center;
  text-align: center;
  justify-items: center;
  min-height: 50vh;
  margin: 0vh;
  padding: rem;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  color: #444;
  background-color: white;
}

.card h3 {
  margin-top: 1rem;
  font-size: 1.25rem;
}

.card a {
  color: inherit;
}

.card-wrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30ch, 1fr));
  grid-gap: 1.5rem;
  max-width: 100vw;
  width: 120ch;
  padding-left: 1rem;
  padding-right: 1rem;
}

.bullet {
  list-style-type: square;
  text-align: left;
  font-weight: 500;
}



/*footer*/
.footer-clean {
  padding:10px 100px;
  background-color:#fff;
  color:#4b4c4d;
  width:100%;
}

.footer-clean h3 {
  margin-top:0;
  margin-bottom:12px;
  font-weight:bold;
  font-size:16px;
}

.footer-clean ul {
  padding:0;
  list-style:none;
  line-height:1.6;
  font-size:14px;
  margin-bottom:0;
}

.footer-clean ul a {
  color:inherit;
  text-decoration:none;
  opacity:0.8;
}

.footer-clean ul a:hover {
  opacity:1;
}

.footer-clean .item.social {
  text-align:right;
}

@media (max-width:767px) {
  .footer-clean .item {
    text-align:center;
    padding-bottom:20px;
  }
}

@media (max-width: 768px) {
  .footer-clean .item.social {
    text-align:center;
  }
}

.footer-clean .item.social > a {
  font-size:24px;
  width:120px;
  height:40px;
  line-height:40px;
  display:inline-block;
  text-align:center;
  border-radius:50%;
  border:1px solid #ccc;
  margin-left:10px;
  margin-top:22px;
  color:inherit;
  opacity:0.75;
}

.footer-clean .item.social > a:hover {
  opacity:0.9;
}

@media (max-width:991px) {
  .footer-clean .item.social > a {
    margin-top:20px;
  }
}

@media (max-width:767px) {
  .footer-clean .item.social > a {
    margin-top:10px;
  }
}

.footer-clean .copyright {
  margin-top:14px;
  margin-bottom:0;
  font-size:13px;
  opacity:0.6;
}

/* Credit to https://epicbootstrap.com/snippets/footer-with-columns */